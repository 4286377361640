var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plantManageForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "绿植名称",
                        prop: "plantName",
                        rules: {
                          required: true,
                          message: "请输入绿植名称",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入绿植名称",
                          width: _vm.width,
                          maxlength: 50,
                        },
                        model: {
                          value: _vm.form.plantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "plantName", $$v)
                          },
                          expression: "form.plantName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "所属科目" } }, [
                    _c("div", { staticClass: "plant-category-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入科",
                              width: _vm.width,
                              maxlength: 30,
                            },
                            model: {
                              value: _vm.form.depart,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "depart", $$v)
                              },
                              expression: "form.depart",
                            },
                          }),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("科"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入属",
                              width: _vm.width,
                              maxlength: 30,
                            },
                            model: {
                              value: _vm.form.attribute,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "attribute", $$v)
                              },
                              expression: "form.attribute",
                            },
                          }),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("属"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入种",
                              width: _vm.width,
                              maxlength: 30,
                            },
                            model: {
                              value: _vm.form.variety,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "variety", $$v)
                              },
                              expression: "form.variety",
                            },
                          }),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("种"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "习性" } },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入习性",
                          width: _vm.width,
                          maxlength: 50,
                        },
                        model: {
                          value: _vm.form.habit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "habit", $$v)
                          },
                          expression: "form.habit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "种植时间" } },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "date",
                          width: _vm.width,
                          maxDate: _vm.maxDate,
                        },
                        model: {
                          value: _vm.form.plantTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "plantTime", $$v)
                          },
                          expression: "form.plantTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "种植地点" } },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入种植地点",
                          width: _vm.width,
                          maxlength: 50,
                        },
                        model: {
                          value: _vm.form.plantAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "plantAddress", $$v)
                          },
                          expression: "form.plantAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        prop: "communityId",
                        rules: {
                          required: true,
                          message: "选项不能为空",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "请选择所属项目",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "负责人" } },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "请选择负责人",
                              width: _vm.width,
                              subjoin: _vm.subjoin,
                              disabled:
                                !_vm.form.communityId ||
                                _vm.form.communityId.length === 0,
                            },
                            model: {
                              value: _vm.form.managerUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "managerUser", $$v)
                              },
                              expression: "form.managerUser",
                            },
                          },
                          "v-select2",
                          _vm.managerUserParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "照片",
                        prop: "imgUrls",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请上传图片",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.imgUrls,
                          limit: 5,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "imgUrls", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "imgUrls", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "注：点击上传图片(1-5张)，图片大小以640*640显示最佳"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "最多输入500字", maxlength: 500 },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }