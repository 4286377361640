<template>
  <div class="plantManageForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item
            label="绿植名称"
            prop="plantName"
            :rules="{ required: true, message: '请输入绿植名称', trigger: 'change' }"
          >
            <v-input v-model="form.plantName" placeholder="请输入绿植名称" :width="width" :maxlength="50" />
          </el-form-item>
          <el-form-item label="所属科目">
            <div class="plant-category-wrapper">
              <div class="content-wrapper">
              <v-input v-model="form.depart" placeholder="请输入科" :width="width" :maxlength="30"/>
              <span class="right-tip">科</span>
              </div>
              <div class="content-wrapper">
                <v-input v-model="form.attribute" placeholder="请输入属" :width="width" :maxlength="30"/>
                <span class="right-tip">属</span>
              </div>
              <div class="content-wrapper">
                <v-input v-model="form.variety" placeholder="请输入种" :width="width" :maxlength="30"/>
                <span class="right-tip">种</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="习性">
            <v-input v-model="form.habit" placeholder="请输入习性" :width="width" :maxlength="50"/>
          </el-form-item>
          <el-form-item label="种植时间">
            <v-datepicker
                v-model="form.plantTime"
                type="date"
                :width="width"
                :maxDate="maxDate"
              />
          </el-form-item>
          <el-form-item label="种植地点">
            <v-input v-model="form.plantAddress" placeholder="请输入种植地点" :width="width" :maxlength="50"/>
          </el-form-item>
          <el-form-item
            label="所属项目"
            prop="communityId"
            :rules="{ required: true, message: '选项不能为空', trigger: 'change' }" >
            <v-select2
              v-model="form.communityId"
              v-bind="communityParams"
              placeholder="请选择所属项目"
              :width="width">
            </v-select2>
          </el-form-item>
          <el-form-item label="负责人">
            <v-select2
              v-model="form.managerUser"
              v-bind="managerUserParams"
              placeholder="请选择负责人"
              :width="width"
              :subjoin="subjoin"
              :disabled="!form.communityId || form.communityId.length === 0"
              >
            </v-select2>
          </el-form-item>
          <el-form-item
            label="照片"
            prop="imgUrls"
            :rules="[
              { type:'array', required: true, message: '请上传图片', trigger: 'change' }
            ]"
          >
            <v-uploader
              :action="uploadURL"
              :imgUrls.sync="form.imgUrls"
              :limit="5"
            >
              <template #tip>
                <span>注：点击上传图片(1-5张)，图片大小以640*640显示最佳</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item label="简介">
              <v-textarea
                v-model="form.remark"
                placeholder="最多输入500字"
                :maxlength="500"
              ></v-textarea>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="状态"
            prop="status"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'change' }
            ]"
          >
            <v-select
              v-model="form.status"
              :options="statusOps"
              :width="width"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { updateURL, queryURL, uploadURL, getUsernameListURL } from './api'
import { statusOps } from './map'
import { vUploader } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'
import moment from 'moment'

export default {
  name: 'PlantManageForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      uploadURL: uploadURL,
      statusOps: statusOps(),
      maxDate: moment().format('YYYY-MM-DD'),
      communityParams: communityParams,
      managerUserParams: {
        searchUrl: getUsernameListURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        response: {
          text: 'username'
        }
      },
      form: {
        id: undefined,
        plantName: '',
        depart: '',
        attribute: '',
        variety: '',
        habit: '',
        plantTime: '',
        plantAddress: '',
        communityId: '',
        managerUser: '',
        imgUrls: [],
        status: 1,
        remark: ''
      },
      submitConfig: {
        submitUrl: updateURL,
        queryUrl: '',
        submitMethod: ''
      },
      isAdd: false
    }
  },

  computed: {
    subjoin () {
      return {
        communityId: this.form.communityId
      }
    }
  },

  mounted () {
    const { id } = this.$route.query
    this.isAdd = !id
    this.submitConfig.submitMethod = this.isAdd ? 'post' : 'put'
    if (!this.isAdd) {
      this.submitConfig.queryUrl = `${queryURL}/${id}`
      console.log('id', this.submitConfig.queryUrl)
      this.$refs.formPanel.getData({ id })
    }
    let title = this.isAdd ? '新增' : '编辑'
    // 设置title
    this.$setBreadcrumb(title)
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      let postData = {}
      // data.imgUrls = JSON.stringify(data.imgUrls)
      Object.keys(data).forEach(item => {
        if (data[item] !== undefined) {
          if (typeof data[item] === 'string') {
            if (data[item].length) {
              postData[item] = data[item]
            }
          } else {
            postData[item] = data[item]
          }
        }
      })
      return postData
    }
  }
}
</script>

<style lang="scss" scoped>
.plantManageForm-wrapper {
  .plant-category-wrapper {
    display: flex;
    justify-content: flex-start;

    .content-wrapper {
      display: flex;
      justify-content: flex-start;

      .right-tip {
        margin-left: 10px;
        margin-right: 20px;
      }
    }
  }

}
</style>
