import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

export {
  statusMap,
  statusOps
}
